import React, { useState } from "react";
import { imgDir } from "../util";

const shopList = [
    {
        title: '南船場店',
        zip: '〒542-0081',
        address: '大阪府大阪市中央区南船場4丁目12-22 心斎橋東榮ビル 1F',
        open: '営業時間 | 12:00~20:00 不定休',
        tel: 'TEL | 06 6245 1268',
        img: imgDir() + '/minamisenba.jpg',
        map: 'https://goo.gl/maps/SiYAwoLyDwhfRA4e6'
    },
    {
        title: 'D-TE SHOE STORE',
        zip: '〒542-0081',
        address: '大阪府大阪市中央区南船場4丁目12-22 心斎橋東榮ビル 1F',
        open: '営業時間 | 12:00~20:00 月曜定休',
        tel: 'TEL | 06 6245 1315',
        img: imgDir() + '/store_d-te.jpg',
        map: 'https://maps.app.goo.gl/uHjWkj2bCfJPppf68'
    },
    {
        title: '三休橋店',
        zip: '〒541-0051',
        address: '大阪府大阪市中央区備後町3丁目1-2 reA.bldg 1F',
        open: '営業時間 | 12:00~20:00 不定休',
        tel: 'TEL | 06 6210 3008',
        img: imgDir() + '/sankyubashi.jpg',
        map: 'https://goo.gl/maps/hyo8Lp27VA48EdN68'
    },
    {
        title: '靱公園店',
        zip: '〒550-0004',
        address: '大阪府大阪市西区靱本町1丁目16-18 クリエートビル1F',
        open: '営業時間 | 12:00~20:00 定休日 | 月・火',
        tel: 'TEL | 06 6567 8447',
        img: imgDir() + '/utsubo.jpg',
        map: 'https://maps.app.goo.gl/sGRqzNFkFdrYyjJm8'
    }
]

export default function Shop(props: any) {
    const [activeShop, setActiveShop] = useState(0)

    return (
        <div id="shop" className="py-20">
            <div className="lg:w-[880px] mx-auto px-4 lg:px-0">
                <h3 className="text-4xl cormorant tracking-wider">SHOP</h3>
                <ul className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-4 mt-10">
                    {shopList.map((shop: any, i: number) =>(
                        <li key={'shop-'+i}>
                            <button onClick={()=>setActiveShop(i)} className={`relative block w-full py-2 text-sm tracking-wider font-bold group ${activeShop===i? 'bg-black text-white': 'bg-white text-black'}`}>
                                <div className="absolute inset-0 border border-black ">
                                </div>
                                {shop.title}
                                {activeShop !== i && (
                                    <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                        {shop.title}
                                    </div>
                                )}
                            </button>
                        </li>
                    ))}
                        <li>
                            <a href="https://centotrenta.shop-pro.jp/" target="_blank" className="relative block w-full py-2 text-sm md:text-xs lg:text-sm tracking-wider font-bold group bg-white text-black">
                                <div className="absolute inset-0 border border-black ">
                                </div>
                                ONLINE STORE
                                <div className="absolute inset-0 -translate-x-1 -translate-y-1 flex justify-center items-center bg-white border border-black pointer-events-none transition-transform group-hover:translate-x-0 group-hover:translate-y-0">
                                    ONLINE STORE
                                    <svg className="w-4 h-4 ml-2"  viewBox="0 0 24 24">
                                        <path fill="currentColor" d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z" />
                                    </svg>
                                </div>
                            </a>
                        </li>
                </ul>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                    <div className="-mx-4 md:mx-0">
                        <img src={shopList[activeShop].img} className="w-full h-auto" />
                    </div>
                    <div className="flex w-full h-full items-center">
                        <div>
                            <h6 className="text-xl font-bold">{shopList[activeShop].title}</h6>
                            <ul className=" text-sm mt-4 leading-6 mb-4">
                                <li>{shopList[activeShop].zip}</li>
                                <li>{shopList[activeShop].address}</li>
                                <li>{shopList[activeShop].open}</li>
                                <li>{shopList[activeShop].tel}</li>
                            </ul>
                            <a href={shopList[activeShop].map} target="_blank" className="text-sm font-bold inline-flex items-center border-b border-black py-1 px-2 transition-colors hover:bg-gray-100">
                                Googleマップで見る
                                <svg className="w-4 h-4 ml-1"  viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M5,17.59L15.59,7H9V5H19V15H17V8.41L6.41,19L5,17.59Z" />
                                </svg>
                            </a>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    )
}